import React from "react";
import "./App.css";
import logo from "./Logo.png"; // Replace with your logo image
import image1 from "./01.jpg"; // Replace with your first image
import image2 from "./02.jpg"; // Replace with your second image
import image3 from "./03.jpg"; // Replace with your third image
import image4 from "./04.jpg"; // Replace with your fourth image

function App() {
    return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <div className="Delivery">
              <div className="Delivery-header">
                <h2>Free Delivery (7AM to 10AM)</h2>
              </div>
              <div className="Delivery-schedule">
                <p>
                  <span>Thursday</span>: Pickering & Ajax &#10074;
                  <span> Friday</span>: North York & Vaughan &#10074;
                  <span> Saturday</span>: Mississauga & Etobicoke &#10074;
                  <span> Sunday</span>: Scarborough & Markham
                </p>
              </div>
            </div>
            <div className="image-row">
              <div className="column">
                <img src={image1} className="App-image" alt="image1" />
                <img src={image2} className="App-image" alt="image2" />
              </div>
              <div className="column">
                <img src={image3} className="App-image1" alt="image3" />
              </div>
              <div className="column">
                <img src={image4} className="App-image1" alt="image4" />
              </div>
            </div>
            {/* <p className="coming-soon">
                        <h2>Coming Soon...!</h2> 
                    </p> */}
          </header>
        </div>
      );
      
}

export default App;
